import { Link } from "gatsby";
import * as React from "react"
import { Helmet } from "react-helmet";
import { Navigation, Content, Banner } from "../components";
import "../styles/main.scss";


// --------------------------------------------------------------------------------
// Banner
// --------------------------------------------------------------------------------
function PageBanner(): JSX.Element {
    const title = <>Why Qebal People Analytics</>;

    const subtitle = <>
        <p>Engage your stakeholders with data‑driven conversations and be more strategic.</p>
        <p>Deliver strategic workforce reports and insights for senior leaders and heads of departments.</p>
        <p>Plan, monitor and track workforce changes to support organisation transformation.</p>
    </>;

    return <Banner
        title={title}
        subtitle={subtitle}
        action={{ to: "/contact-us/", caption: "Request a demo" }}
    />;
}


// --------------------------------------------------------------------------------
// Introduction
// --------------------------------------------------------------------------------
function PageIntro(): JSX.Element {
    const intro = <>
        <h1>Why Qebal People Analytics</h1>
        <p>Engage your stakeholders with data‑driven conversations and be more strategic.</p>
        <p>Deliver strategic workforce reports and insights for senior leaders and heads of departments.</p>
        <p>Plan, monitor and track workforce changes to support organisation transformation.</p>

        <div className="d-grid d-sm-inline gap-2">
            <Link className="btn btn-primary px-sm-4" to="/contact-us/">Request a demo</Link>
        </div>
    </>;

    return <div className="row row-cols-1 row-cols-sm-1 pb-4 gy-4">
        <div className="col text-sm-center px-4">
            {intro}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Body
// --------------------------------------------------------------------------------
function PageBody(): JSX.Element {
    const body1 = <>

        <h2>People analytics, metrics and insights</h2>
        <p>
            Pre-built reports with key HR metrics to help you offer insight to stakeholders on their team's performance, key strengths and opportunities for improvement.
        </p>
        <p>
            <Link to="/people-analytics/">More about people analytics...</Link>
        </p>
    </>;

    const body2 = <>
        <h2>Workforce data for decision makers</h2>
        <p>
            Identify workforce opportunities and risks that need to be communicated to your stakeholders. Provide clear overviews with the supporting information necessary to explain complex situations.
        </p>
        <p>
            <Link to="/workforce-planning/">More about workforce planning...</Link>
        </p>
    </>;

    const body3 = <>
        <h2>Workforce reports and dashboards</h2>
        <p>
            Combine your employee, financial and other company data to allow your HR team to analyse people related data and make future workforce predictions in a safe, HR controlled environment.
        </p>
        <p>
            <Link to="/dashboards-and-reports/">More about dashboards and reports...</Link>
        </p>
    </>;

    const body4 = <>
        <h2>Plan your workforce initiatives</h2>
        <p>
            Create reports to design and plan the transformation of your workforce.
            Provide dashboards, regular reports and metrics to stakeholders through
            the critical stages of your change programmes.
        </p>

        <p>
            <Link to="/transformation-analytics/">More about transformation analytics...</Link>
        </p>
    </>;

    return <div className="row row-cols-1 row-cols-sm-2 pt-4 pb-4 gy-4">
        <div className="col px-4">
            {body1}
        </div>
        <div className="col px-4">
            {body2}
        </div>
        <div className="col px-4">
            {body3}
        </div>
        <div className="col px-4">
            {body4}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Body
// --------------------------------------------------------------------------------
function PageQuote(): JSX.Element {
    const intro = <>
        <h2>Make HR an ally of your business stakeholders</h2>
        <p>Qebal People Analytics transforms your HR data into reports and dashboards with key employee metrics.</p>
        <p>Manage and plan your workforce initiatives using the data you already have.</p>
        <p>Move from opinion to evidence based decision making with insightful dashboards and reports.</p>
    </>;

    return <div className="row row-cols-1 row-cols-sm-1 pb-4 gy-4">
        <div className="col text-sm-center px-4 qbl-block-1 py-4">
            {intro}
        </div>
    </div>;
}


const WhyQebal = () => {
    return <>
        <Helmet title="Qebal / Why Qebal Analytics" htmlAttributes={{ lang: 'en' }} />
        <Navigation />
        <Content>
            <div className="container-lg">
                {/* <PageBanner /> */}
                <PageIntro />
                <PageBody />

                <div className="mt-4">
                    <PageQuote />
                </div>
            </div>
        </Content>
    </>
};

export default WhyQebal;